<template>
  <main id="content" role="main" class="overflow-hidden">

    <!-- ========== Hero Section ========== -->
    <div class="position-relative overflow-hidden bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/banking-open-api-bg.svg') + ')'}">
      <!-- Breadcrumb -->
      <div class="container space-top-2 mt-md-6 pl-0">
        <div class="row justify-content-sm-between">
          <div class="col-md">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-md-3 mb-0">
                <li class="breadcrumb-item">
                  <!-- <a href="/resource-library"> -->
                  <router-link :to="{ path: '/resource-library' }">
                    Resource Library
                  </router-link>
                  <!-- </a> -->
                </li>
                <li class="breadcrumb-item active" aria-current="page">Getting Prepared with Banking Open API Phase III &amp; IV with beNovelty</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- Title -->
      <div class="container space-top-1 space-bottom-2 position-relative">
        <div class="row justify-content-lg-between align-items-lg-center">
          <div class="col-md-6">
            <div class="mb-5 mb-md-0">
              <h1>Getting Prepared with Banking Open API Phase III &amp; IV with beNovelty</h1>
            </div>
          </div>
          <div class="col-md-6">
            <img class="img-fluid" src="@/assets/svg/illustrations/banking-open-api-hero.svg">
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Hero Section ========== -->

    <!-- ========== About Section ========== -->
    <div class="container space-2 space-lg-3">
      <div class="row align-items-lg-center">
        <div class="col-lg-6">
          <div class="mb-5 mb-md-7">
            <h2 class="mb-3">What are Open APIs?</h2>
            <p>Over recent years, Open APIs have been applied by companies in different industry sectors to expand their core businesses and develop ecosystems that enable relevant, interconnected and intelligent customer experiences. For example, travel agency websites have leveraged Open APIs to access data from hotels and airlines in order to provide real-time booking and ticketing services.</p>
            <p class="small">* Contents on this page are extracted from “The Next Phase of the Banking Open API Journey” by HKMA, 2021</p>
          </div>
        </div>
        <div class="col-lg-6" data-aos="fade-left">
          <div class="w-90 w-lg-100 mx-auto">
            <img class="img-fluid" src="@/assets/img/resource-library/banking-open-api/open-apis.svg">
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End About Section ========== -->

    <!-- ========== Four Phases Section ========== -->
    <div class="bg-light">
      <div class="container space-2 space-lg-3">
        <!-- Title -->
        <div class="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
          <h2 class="mb-3">The four-phased approach of the Open API Framework in Hong Kong</h2>
        </div>
        <!-- Steps -->
        <div class="row">
          <div class="col-md-6 col-lg-3 mb-5 mb-md-0">
            <div class="text-center px-lg-3">
              <figure class="max-w-10rem mx-auto mb-4">
                <img class="img-fluid" src="@/assets/svg/icons/phase-1.svg">
              </figure>
              <h4>I. Product and service information</h4>
              <p>“Read-only” information offered by banks, providing details of their products and services.</p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 mb-5 mb-md-0">
            <div class="text-center px-lg-3">
              <figure class="max-w-10rem mx-auto mb-4">
                <img class="img-fluid" src="@/assets/svg/icons/phase-2.svg">
              </figure>
              <h4>II. Subscriptions and new applications for products/services</h4>
              <p>Customer acquisition processes, such as online submissions/applications for credit cards, loans, or other bank products.</p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 mb-5 mb-md-0">
            <div class="text-center px-lg-3">
              <figure class="max-w-10rem mx-auto mb-4">
                <img class="img-fluid" src="@/assets/svg/icons/phase-3.svg">
              </figure>
              <h4>III. Account information</h4>
              <p>Retrieval and alteration (where applicable) of the account information of authenticated customers, e.g. account balances, transactions (balances, transaction history, etc.), for stand-alone or aggregated viewing.</p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 mb-5 mb-md-0">
            <div class="text-center px-lg-3">
              <figure class="max-w-10rem mx-auto mb-4">
                <img class="img-fluid" src="@/assets/svg/icons/phase-4.svg">
              </figure>
              <h4>IV. Transactions</h4>
              <p>Banking transactions and payments or scheduled payments/transfers initiated by authenticated customers.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Four Phases Section ========== -->

    <!-- ========== Stats Section ========== -->
    <div class="container space-2 space-lg-3">
      <!-- Title -->
      <div class="w-md-80 w-lg-60 text-center mx-md-auto mb-5 mb-md-9">
        <h2 class="mb-3">Implementation Status of Banking Open APIs in Hong Kong</h2>
        <p>Since the issuance of the Open API Framework in July 2018, retail banks in Hong Kong have made encouraging progress in building capabilities to adopt banking Open APIs in the areas of strategy, organisation structure and API infrastructure.</p>
      </div>
      <!-- Stats -->
      <div class="w-lg-80 mx-auto text-center">
        <div class="row">
          <div class="col-12 col-md-6 mb-3">
            <div class="card h-100">
              <div class="card-body">
                <span class="h1 text-primary">93%</span>
                <p>of surveyed banks have already defined, or are planning to define, a banking Open API strategy</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <div class="card h-100">
              <div class="card-body">
                <span class="h1 text-primary">50% +</span>
                <p>of surveyed banks have set up, or plan to set up a central team to develop a bank-wide strategy for banking Open API development</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <div class="card h-100">
              <div class="card-body">
                <span class="h1 text-primary">86%</span>
                <p>of surveyed banks have developed a banking Open API infrastructure</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <div class="card h-100">
              <div class="card-body">
                <span class="h1 text-primary">89%</span>
                <p>of the surveyed banks have allocated a budget for banking Open API development or have already invested in various Open API initiatives</p>
              </div>
            </div>
          </div>
        </div>
        <span class="small">Source: “The Next Phase of the Banking Open API Journey” by HKMA, 2021</span>
      </div>
    </div>
    <!-- ========== End Stats Section ========== -->

    <!-- ========== Benefits Section ========== -->
    <div class="bg-light">
      <div class="container space-2 space-lg-3">
        <!-- Title -->
        <div class="w-md-80 w-lg-75 text-center mx-md-auto mb-5 mb-md-9">
          <h2 class="mb-3">Benefits of implementing Banking Open APIs</h2>
          <p>Banking Open APIs give TSPs the opportunity to innovate and design solutions to meet customers’ digital needs, while banks can leverage TSPs’ capabilities to improve customer experiences. </p>
        </div>
        <!-- Benefits -->
        <div class="row align-items-lg-center">
          <div class="col-lg-6">
            <div class="mb-5 mb-md-7">
              <div class="media mb-3">
                <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body">
                  <p>New revenue streams from new products and services</p>
                </div>
              </div>
              <div class="media mb-3">
                <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body">
                  <p>Customer satisfaction and retention</p>
                </div>
              </div>
              <div class="media mb-3">
                <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body">
                  <p>New collaboration opportunities with TSPs</p>
                </div>
              </div>
              <div class="media mb-3">
                <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body">
                  <p>Innovation facilitation</p>
                </div>
              </div>
              <div class="media mb-3">
                <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body">
                  <p>Acceleration of digital technology adoption</p>
                </div>
              </div>
              <div class="media mb-3">
                <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body">
                  <p>Personalisation of existing products/services</p>
                </div>
              </div>
              <div class="media mb-3">
                <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body">
                  <p>Cost saving and efficiency</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6" data-aos="fade-left">
            <div class="w-90 w-lg-100 mx-auto">
              <img class="img-fluid" src="@/assets/img/resource-library/banking-open-api/open-apis-benefits.svg">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Benefits Section ========== -->

    <!-- ========== Practices Section ========== -->
    <div class="container space-2 space-lg-3">
      <!-- Title -->
      <div class="w-md-80 w-lg-60 text-center mx-md-auto mb-5 mb-md-9">
        <h2 class="mb-3">Essential practices for Phase III and IV implementation</h2>
      </div>
      <!-- Accordion -->
      <div class="row align-items-center">
        <div class="col-lg-6 order-lg-2">
          <div id="basicsAccordion">
            <div class="card shadow-none mb-3">
              <div id="basicsHeadingOne" class="card-header card-collapse">
                <a class="btn btn-link btn-block d-flex justify-content-between card-btn collapsed bg-white px-0" href="javascript:;" role="button" data-toggle="collapse" data-target="#basicsCollapseOne" aria-expanded="false" aria-controls="basicsCollapseOne">
                  <span><i class="fas fa-tasks mr-2" />Adopt appropriate risk management strategies</span>

                  <span class="card-btn-toggle">
                    <span class="card-btn-toggle-default">+</span>
                    <span class="card-btn-toggle-active">−</span>
                  </span>
                </a>
              </div>
              <div id="basicsCollapseOne" class="collapse" aria-labelledby="basicsHeadingOne" data-parent="#basicsAccordion">
                <div class="card-body px-0">
                  <p>Monitoring and reviews of risk management frameworks need to be regularly conducted to protect against risks associated with cybersecurity, system resilience, data privacy, liability, and fraud and money laundering.</p>
                </div>
              </div>
            </div>

            <div class="card shadow-none mb-3">
              <div id="basicsHeadingTwo" class="card-header card-collapse">
                <a class="btn btn-link btn-block d-flex justify-content-between card-btn collapsed bg-white px-0" href="javascript:;" role="button" data-toggle="collapse" data-target="#basicsCollapseTwo" aria-expanded="false" aria-controls="basicsCollapseTwo">
                  <span><i class="fas fa-shield-alt mr-2" />Introduce appropriate protection mechanisms</span>

                  <span class="card-btn-toggle">
                    <span class="card-btn-toggle-default">+</span>
                    <span class="card-btn-toggle-active">−</span>
                  </span>
                </a>
              </div>
              <div id="basicsCollapseTwo" class="collapse" aria-labelledby="basicsHeadingTwo" data-parent="#basicsAccordion">
                <div class="card-body px-0">
                  <p>To mitigate risks, protection measures need to be in place to address key areas of data protection and retention, customer consent, disclosure and transparency, liability, complaint and redress handling.</p>
                </div>
              </div>
            </div>

            <div class="card shadow-none mb-3">
              <div id="basicsHeadingThree" class="card-header card-collapse">
                <a class="btn btn-link btn-block d-flex justify-content-between card-btn collapsed bg-white px-0" href="javascript:;" role="button" data-toggle="collapse" data-target="#basicsCollapseThree" aria-expanded="false" aria-controls="basicsCollapseThree">
                  <span class="mr-1"><i class="fas fa-users mr-2" />Design compelling banking Open API propositions for customers</span>

                  <span class="card-btn-toggle">
                    <span class="card-btn-toggle-default">+</span>
                    <span class="card-btn-toggle-active">−</span>
                  </span>
                </a>
              </div>
              <div id="basicsCollapseThree" class="collapse" aria-labelledby="basicsHeadingThree" data-parent="#basicsAccordion">
                <div class="card-body px-0">
                  <p>Designing customer-centric propositions, fostering trust towards TSPs, and educating customers are essential both for satisfying market needs and driving adoption of banking Open APIs.</p>
                </div>
              </div>
            </div>

            <div class="card shadow-none mb-3">
              <div id="basicsHeadingFour" class="card-header card-collapse">
                <a class="btn btn-link btn-block d-flex justify-content-between card-btn collapsed bg-white px-0" href="javascript:;" role="button" data-toggle="collapse" data-target="#basicsCollapseFour" aria-expanded="false" aria-controls="basicsCollapseFour">
                  <span><i class="fas fa-university mr-2" />Understand the range of bank capabilities required</span>

                  <span class="card-btn-toggle">
                    <span class="card-btn-toggle-default">+</span>
                    <span class="card-btn-toggle-active">−</span>
                  </span>
                </a>
              </div>
              <div id="basicsCollapseFour" class="collapse" aria-labelledby="basicsHeadingFour" data-parent="#basicsAccordion">
                <div class="card-body px-0">
                  <p>A federated operating model, a robust core system and technical enablers (e.g. API portals) are key capabilities to facilitate implementation.</p>
                </div>
              </div>
            </div>

            <div class="card shadow-none mb-3">
              <div id="basicsHeadingFive" class="card-header card-collapse">
                <a class="btn btn-link btn-block d-flex justify-content-between card-btn collapsed bg-white px-0" href="javascript:;" role="button" data-toggle="collapse" data-target="#basicsCollapseFive" aria-expanded="false" aria-controls="basicsCollapseFive">
                  <span><i class="fas fa-tools mr-2" />Understand the range of TSP capabilities required</span>

                  <span class="card-btn-toggle">
                    <span class="card-btn-toggle-default">+</span>
                    <span class="card-btn-toggle-active">−</span>
                  </span>
                </a>
              </div>
              <div id="basicsCollapseFive" class="collapse" aria-labelledby="basicsHeadingFive" data-parent="#basicsAccordion">
                <div class="card-body px-0">
                  <p>Depending on the nature of their business, TSPs will need to have a well-defined operating model, strong data management, and information security capabilities.</p>
                </div>
              </div>
            </div>

            <div class="card shadow-none mb-3">
              <div id="basicsHeadingSix" class="card-header card-collapse">
                <a class="btn btn-link btn-block d-flex justify-content-between card-btn collapsed bg-white px-0" href="javascript:;" role="button" data-toggle="collapse" data-target="#basicsCollapseSix" aria-expanded="false" aria-controls="basicsCollapseSix">
                  <span><i class="fas fa-balance-scale mr-2" />Select one or more appropriate business/ finance models</span>

                  <span class="card-btn-toggle">
                    <span class="card-btn-toggle-default">+</span>
                    <span class="card-btn-toggle-active">−</span>
                  </span>
                </a>
              </div>
              <div id="basicsCollapseSix" class="collapse" aria-labelledby="basicsHeadingSix" data-parent="#basicsAccordion">
                <div class="card-body px-0">
                  <p>Banks and TSPs should develop a suitable monetisation strategy, which includes a range of direct and indirect monetisation models, according to the use cases to be implemented.</p>
                </div>
              </div>
            </div>

            <div class="card shadow-none mb-3">
              <div id="basicsHeadingSeven" class="card-header card-collapse">
                <a class="btn btn-link btn-block d-flex justify-content-between card-btn collapsed bg-white px-0" href="javascript:;" role="button" data-toggle="collapse" data-target="#basicsCollapseSeven" aria-expanded="false" aria-controls="basicsCollapseSeven">
                  <span><i class="far fa-chart-bar mr-2" />Monitor the banking Open API ecosystem</span>

                  <span class="card-btn-toggle">
                    <span class="card-btn-toggle-default">+</span>
                    <span class="card-btn-toggle-active">−</span>
                  </span>
                </a>
              </div>
              <div id="basicsCollapseSeven" class="collapse" aria-labelledby="basicsHeadingSeven" data-parent="#basicsAccordion">
                <div class="card-body px-0">
                  <p>Monitoring mechanisms for fraud monitoring, API availability and performance monitoring are crucial to building trust and transparency among ecosystem participants and ensuring reliable banking Open API operations.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 order-lg-1" data-aos="fade-right">
          <div class="w-90 w-lg-100 mx-auto">
            <img class="img-fluid" src="@/assets/img/resource-library/banking-open-api/open-apis-practices.svg">
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Practices Section ========== -->

    <!-- ========== Case Study ========== -->
    <!-- <figure>
      <svg
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        height="64px"
        viewBox="0 0 1921 273"
        style="margin-bottom: -8px; enable-background:new 0 0 1921 273;"
        xml:space="preserve"
      >
        <polygon fill="#d7edff63" points="0,273 1921,273 1921,0 " />
      </svg>
    </figure>
    <div class="bg-light">
      <div class="container space-2">
        <div class="row align-items-lg-center">
          <div class="col-lg-6">
            <div class="mb-5 mb-md-7">
              <h2 class="mb-3">Case Study</h2>
              <div class="media mb-3">
                <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body">
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo quasi pariatur est odit tenetur aspernatur voluptatum!</p>
                </div>
              </div>
              <div class="media mb-3">
                <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body">
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum a perspiciatis velit debitis veritatis deserunt sint ipsam, odit optio aliquam.</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6" data-aos="fade-right">
            <div class="w-90 w-lg-100 mx-auto">
              <img class="img-fluid" src="@/assets/svg/illustrations/cyber-insurance/about.svg">
            </div>
          </div>
        </div>
      </div>
    </div>
    <figure>
      <svg
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        height="64px"
        viewBox="0 0 1921 273"
        style="margin-bottom: -8px; enable-background:new 0 0 1921 273;"
        xml:space="preserve"
      >
        <polygon fill="#d7edff63" points="1921,0 0,0 0,273 " />
      </svg>
    </figure> -->
    <!-- ========== End Case Study ========== -->

    <!-- ==========  CTA Section ==========  -->
    <div class="gradient-y-sm-primary">
      <div class="container space-2 space-lg-3">
        <div class="w-md-80 text-center mx-md-auto mb-5">
          <span class="d-block small font-weight-bold text-cap mb-2">you're in good company</span>
          <h2>Powering Innovative Enterprises</h2>
        </div>

        <PartnerCarousel :items="api_partner_list" />

        <div class="mt-4">
          <ContactUsBar :detail="{ title: 'Want to know more about Banking Open API Implementation?' }" />
        </div>
      </div>
    </div>
    <!-- ========== End CTA Section ==========  -->
  </main>
</template>

<script>
const $ = require('jquery')
import PartnerCarousel from '@/components/partner-carousel'
import ContactUsBar from '@/components/contact-us-bar'
import HSGoTo from '@/assets/vendor/hs-go-to/src/js/hs-go-to'
import 'bootstrap'
import '@/assets/js/hs.core.js'
import '@/assets/js/hs.slick-carousel.js'
import '@/assets/vendor/slick-carousel/slick/slick.js'
import { mapGetters } from 'vuex'
export default {
  name: 'BankingOpenAPI',
  components: {
    PartnerCarousel,
    ContactUsBar
  },
  data() {
    return {
      feature_list: [
        {
          image_url: 'icon-31.svg',
          number: '1',
          content: 'Product and service information',
          description: '"Read-only” information offered by banks, providing details of their products and services.'
        },
        {
          image_url: 'icon-22.svg',
          number: '2',
          content: 'Subscriptions and new applications for products/services',
          description: 'Customer acquisition processes, such as online submissions/applications for credit cards, loans, or other bank products.'
        },
        {
          image_url: 'icon-40.svg',
          number: '3',
          content: 'Account information',
          description: 'Retrieval and alteration (where applicable) of the account information of authenticated customers, e.g. account balances, transactions (balances, transaction history, etc.), for stand-alone or aggregated viewing.'
        },
        {
          image_url: 'icon-5.svg',
          number: '4',
          content: 'Transactions',
          description: 'Banking transactions and payments or scheduled payments/transfers initiated by authenticated customers.'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
    ])
  },
  mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
  },
  created() {
    this.api_partner_list = this.$store.getters.partners.api_partner_list
  },
  methods: {
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'Getting Prepared with Banking Open API Phase III & IV with beNovelty | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/resource-library/banking-open-api' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'Getting Prepared with Banking Open API Phase III & IV with beNovelty | beNovelty' },
        { property: 'og:description', content: 'The HKMA announced the implementation plan for Phases III and IV of the Open API Framework in May 2021. Get to know how to better prepare for the next phase of the Banking Open API journey.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/resource-library/banking-opan-api/banking-open-api-cover.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/resource-library/banking-open-api' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../../assets/vendor/slick-carousel/slick/slick.css");
</style>
